import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';
import loadingIcon from '../assets/images/loading.gif';

const Header = lazy(() => import('../Header'));

const Event = () => {
  //this holds our initial state
  //totalInvited is from the Counter component
  //We set totalInvited here as we want it to update as the app rerenders
  const [eventDetails, setEventDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(`https://ecards-service.vercel.app/ecards/events/${id}`)
      .then(res => res.json())
      .then(data => {
        setEventDetails({
          ...data.data,
          foodOptions: data.data?.foodOptions
            ? data.data?.foodOptions?.filter(option => option !== null)
            : [],
        });
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {eventDetails && (
        <Suspense fallback={<img source={loadingIcon} alt="Loading..." />}>
          <Header card={eventDetails} />
        </Suspense>
      )}
    </div>
  );
};

export default Event;
