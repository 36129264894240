/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import '../assets/fonts/Abhaya Libre Regular.ttf';
import '../assets/fonts/Abhaya Libre Medium.ttf';
import '../assets/fonts/Abhaya Libre SemiBold.ttf';
import '../assets/fonts/Abhaya Libre Bold.ttf';
import '../assets/fonts/Abhaya Libre ExtraBold.ttf';
import '../assets/fonts/Playlist Script.otf';

const ECardItem = ({ editor }) => {
  if (editor.isImage) {
    const variableStyle = {
      width: editor.width,
      height: editor.height,
      position: 'absolute',
      transform: `rotate(${editor.rotationAngle}deg)`,
      left: editor.posX,
      top: editor.posY,
    };
    return (
      <div>
        <img
          src={editor.image.uri}
          style={{
            ...editor.externalStyle,
            ...variableStyle,
          }}
        />
      </div>
    );
  }

  const variableStyle = {
    width: editor.width,
    height: editor.height,
    position: 'absolute',
    transform: `rotate(${editor.rotationAngle}deg)`,
    left: editor.posX + 8,
    top: editor.posY + 8,
    lineHeight: editor?.externalStyle?.lineHeight
      ? `${editor.externalStyle.lineHeight}px`
      : 'initial',
    margin: 0,
    padding: 0,
    whiteSpace: 'break-spaces',
  };

  return (
    <div>
      <pre style={{ ...editor.externalStyle, ...variableStyle }}>
        {editor.text}
      </pre>
    </div>
  );
};

export default ECardItem;
