export const formatDateDDMMYYYY = date => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  return `${day}.${month}.${year}`;
};

export const mainPageStyles = {
  bold: {
    fontWeight: 'bold',
  },
  modalContent: {
    width: 400,
    height: 600,
    position: 'relative',
  },
  modalImage: {
    width: 400,
    height: 600,
    position: 'absolute',
    borderRadius: 16,
  },
  cardContainer: {
    flex: 1,
  },
  viewShotContainer: {
    flex: 1,
  },
  mainContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: 100,
    height: 100,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerContainer: {
    justifyContent: 'center',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  title: {
    marginTop: 20,
    fontWeight: 400,
    fontStyle: 'normal',
    color: 'rgb(0, 0, 0)',
    textDecoration: 'none',
    fontSize: 38,
    fontFamily: 'NixieOne-Regular',
  },
  columnItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(250, 254, 255)',
    borderColor: 'rgb(237, 237, 237)',
    borderWidth: 3,
    borderRadius: 2,
    borderStyle: 'solid',
    alignItems: 'center',
    paddingBottom: 20,
    height: 292,
    justifyContent: 'space-between',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  button: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: 'rgb(220, 220, 220)',
    borderRadius: 40,
    width: 154,
    textAlign: 'center',
    borderStyle: 'solid',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    marginLeft: 8,
    marginRight: 8,
    cursor: 'pointer',
  },
  buttonText: {
    fontSize: 20,
    fontFamily: 'GlacialIndifference-Bold',
  },
  yesButtonText: {
    color: '#2CAA92',
  },
  noButtonText: {
    color: '#ED6609',
  },
  maybeButtonText: {
    color: '#545454',
  },
  textItem: {
    fontSize: 20,
    marginTop: 52,
    fontFamily: 'GlacialIndifference-Regular',
  },
  formContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 640,
  },
  eventDetails: {
    textItem: {
      fontSize: 17,
      marginBottom: 12,
      alignSelf: 'center',
      fontFamily: 'GlacialIndifference-Regular',
    },
    black: {
      color: 'rgb(0, 0, 0)',
    },
    container: {
      justifyContent: 'initial',
      alignItems: 'initial',
      padding: 20,
      display: 'flex',
      overflow: 'auto',
    },
    smallTextItem: {
      fontSize: 15,
      fontFamily: 'GlacialIndifference-Regular',
      marginBottom: 4,
    },
    smallerTextItem: {
      fontSize: 13,
      fontFamily: 'GlacialIndifference-Regular',
    },
    orangeText: {
      color: '#ED6609',
    },
    foodOptions: {
      fontSize: 16,
      alignSelf: 'center',
      marginTop: 12,
      marginBottom: 12,
      fontFamily: 'GlacialIndifference-Regular',
    },
    foodOptionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingLeft: 20,
      paddingRight: 20,
    },
    foodOptionItem: {
      flex: 1,
      textAlign: 'center',
      borderRight: '1px solid black',
      height: 32,
      lineHeight: 2,
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      marginRight: 8,
    },
    notesContainer: {
      marginTop: 20,
    },
  },
};

export const modalStyles = {
  message: {
    fontSize: 16,
    fontWeight: 'bold',

    width: '80%',
    marginTop: '25%',
    textAlign: 'center',
  },
  topMargin: {
    marginTop: 20,
  },
  helperLabel: {
    fontSize: 10,
    marginBottom: 4,
  },
  submitButton: {
    marginTop: '25%',
  },
  controlItem: {
    alignSelf: 'flex-start',
    color: '#545454',
    fontSize: 13,
    fontFamily: 'GlacialIndifference-Regular',
    flex: 'initial',
  },
  guestControlItem: {
    width: '100%',
    marginBottom: 12,
  },
  foodPreferences: {
    fontSize: 16,
    flex: 'initial',
    marginTop: 8,
    fontFamily: 'GlacialIndifference-Regular',
    marginBottom: 16,
  },
  removeButton: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 12,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  inputControlItem: {
    marginBottom: 8,
    borderRadius: 8,
    borderWidth: 1,
    marginLeft: 0,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
    width: '100%',
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  text: {
    color: '#545454',
    fontSize: 16,
    flex: 'initial',
    fontFamily: 'GlacialIndifference-Regular',
    textAlign: 'center',
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'auto',
  },
  container: {
    position: 'relative',
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    backgroundColor: 'rgb(250, 254, 255)',
    borderColor: 'black',
    borderWidth: 1,
    borderRadius: 20,
    borderStyle: 'solid',
    alignItems: 'center',
    paddingBottom: 20,
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontFamily: 'GlacialIndifference-Bold',
    marginBottom: 8,
  },
  greenText: {
    color: '#2CAA92',
  },
  largeText: {
    fontSize: 32,
    textAlign: 'center',
    width: '80%',
  },
  orangeText: {
    color: '#ED6609',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'transparent',
    width: 40,
    height: 40,
    fontSize: 20,
  },
};

export const dateIsInPast = date => {
  const now = new Date();
  return date < now;
};

export const getShortDateString = dateString => {
  const options = { month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Add suffix to the day
  const day = date.getDate();
  let daySuffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = 'st';
  } else if (day === 2 || day === 22) {
    daySuffix = 'nd';
  } else if (day === 3 || day === 23) {
    daySuffix = 'rd';
  }

  return formattedDate.replace(/\d+/, `$&${daySuffix}`);
};
