import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../App.css';

//We renamed these files to index.js. React will search the folders
//of the same name and will looks for index.js by default
import HeaderCard from './HeaderCard';

const Card = () => {
  //this holds our initial state
  //totalInvited is from the Counter component
  //We set totalInvited here as we want it to update as the app rerenders
  const [cardDetails, setCardDetails] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(`https://ecards-service.vercel.app/ecards/userCards/${id}`)
      .then(res => res.json())
      .then(data => {
        setCardDetails(data.data);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {cardDetails && <HeaderCard card={cardDetails} />}
    </div>
  );
};

export default Card;
