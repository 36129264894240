/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import ECardItem from '../ECardItem';
import { mainPageStyles } from '../../utils';
import logo from '../../assets/images/logo.png';

const HeaderCard = ({ card = {} }) => {
  return (
    <div style={mainPageStyles.mainContainer}>
      <img src={logo} style={mainPageStyles.logo} />
      <div className="container" style={mainPageStyles.innerContainer}>
        <div className="image">
          <div style={mainPageStyles.modalContent}>
            <div style={mainPageStyles.cardContainer}>
              <div style={mainPageStyles.viewShotContainer}>
                <img
                  src={card?.backgroundImageUrl}
                  style={mainPageStyles.modalImage}
                />
                {card?.textItems?.map((editor, index) => {
                  return <ECardItem editor={editor} key={editor.id + index} />;
                })}
                {card?.imageItems?.map((editor, index) => {
                  return <ECardItem editor={editor} key={editor.id + index} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCard;
